import { TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetAccountInfoQuery } from '../../../api/account';
import { useGetAgentInfoMutation } from '../../../api/agent';
import { useAppDispatch } from '../../../store';
import { setLoadingModal } from '../../../store/slice/modal';

const Contents = () => {

  const dispatch = useAppDispatch();

  const { id } = useParams();
  const { data, isLoading, refetch } = useGetAccountInfoQuery(id);
  //get agent information with useGetAgentInfoMutation
  const [agentApi] = useGetAgentInfoMutation();
  const attorneyData = data?.data;
  const [agentInfo, setAgentInfo] = useState({});

  //get html element by id = genos
  const genos = document.getElementById('genos');
  //hide genos
  genos.style.display = 'none';
  //get html element by id =chatbot-toggler
  const chatbotToggler = document.getElementById('chatbot-toggler');
  //hide chatbot-toggler
  chatbotToggler.style.display = 'none';

  useEffect(() => {
    //Loading modal    
    if (isLoading) {
      dispatch(
        setLoadingModal({
          modalState: true,
        })
      );
    } else {
      dispatch(
        setLoadingModal({
          modalState: false,
        })
      );
    }
  } , [isLoading]);

  useEffect(() => {
      //get agent information
    if(attorneyData?.agentNo){
      agentApi(`agentNo/${attorneyData?.agentNo}`)
        .unwrap()
        .then((res) => {
          if(res?.data?.length > 0){
            setAgentInfo(res.data[0]);
          }
        });
      }
  } , [attorneyData]);
  return (
    <div style={{ padding: '20px', whiteSpace: "pre-wrap"}}>
      <h1>{agentInfo.name} 변리사</h1>
      <TextField
        label="소속"
        value={agentInfo.officeName || '사무실 정보가 없습니다.'}
        fullWidth
        margin='normal'
        disabled
        sx={{
          "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: 'black',
          },
        }}
      />
      <TextField
        label="주분야"
        value={agentInfo.mainArea || '주분야 정보가 없습니다.'}
        fullWidth
        margin='normal'
        disabled
        sx={{
          "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: 'black',
          },
        }}
      />
      {/* 부분야 1, 2 , 3 을 한 라인에 보여주자 */}
      <TextField
        label="부분야 1"
        value={agentInfo.subArea1 || '부분야 정보가 없습니다.'}
        fullWidth
        margin='normal'
        disabled
        sx={{
          "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: 'black',
          },
        }}
      />
      <TextField
        label="부분야 2"
        value={agentInfo.subArea2 || '부분야 정보가 없습니다.'}
        fullWidth
        margin='normal'
        disabled
        sx={{
          "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: 'black',
          },
        }}
      />
      <TextField
        label="부분야 3"
        value={agentInfo.subArea3 || '부분야 정보가 없습니다.'}
        fullWidth
        margin='normal'
        disabled
        sx={{
          "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: 'black',
          },
        }}
      />
      <TextField
        label="경력"
        value={agentInfo?.career || '경력 정보가 없습니다.'}
        fullWidth
        multiline
        margin='normal'
        disabled
        sx={{
          "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: 'black',
          },
        }}
      />
      <TextField
        label="학력"
        value={agentInfo.education || '학력 정보가 없습니다.'}
        fullWidth
        multiline
        margin='normal'
        disabled
        sx={{
          "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: 'black',
          },
        }}
      />
      <TextField
        label="자격 사항"
        value={agentInfo.qualification || '자격증 정보가 없습니다.'}
        fullWidth
        margin='normal'
        disabled
        sx={{
          "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: 'black',
          },
        }}
      />
    </div>
  );
};

export default Contents;