import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useGetAccountInfoQuery } from "../../../api/account";
import { useGetAgentQuery, useUpdateAgentInfoMutation, useUploadResumePDFMutation } from "../../../api/agent";
import { Storage } from "../../../modules/Storage";
import { useAppDispatch } from "../../../store";
import { setAlertModal, setLoadingModal, setTwoButtonModal } from "../../../store/slice/modal";
import "./Contents.css";

const Contents = () =>{
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [accountKey] = useState(Storage.get('accountKey'));
  const [accountId] = useState(Storage.get('accountId'));
  const [agentNo] = useState(Storage.get('agentNo'));
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [isLogin] = useState(accountKey ? true : false);
  const { data: agentInfo, refetch} = useGetAgentQuery(agentNo);
  const { data: accountInfo} = useGetAccountInfoQuery(accountId);
  const [uploadResumeAPI] = useUploadResumePDFMutation();
  const [updateAgentInfoAPI] = useUpdateAgentInfoMutation();
  const role = Storage.get("role") === "client" ? "client" : "attorney";
  const { register, handleSubmit, setValue } = useForm();
  const [isActive, setIsActive] = useState(false);
  useEffect(()=>{
    // console.log('agentInfo', agentInfo)
      if(!isLogin){
          dispatch(
              setAlertModal({
                  modalState : true,
                  modalData: {
                      title:'로그인이 필요합니다.',
                      message: '로그인 페이지로 이동합니다.'
                  },
                  callback: () => {
                      navigate('/login');
                  }
              })
          )
      } else {
          if(role !== "attorney"){
              dispatch(
                  setAlertModal({
                      modalState : true,
                      modalData: {
                          title:'변경 권한이 없습니다.',
                          message: '변경 권한이 없습니다.'
                      },
                      callback: () => {
                          navigate('/mypage');
                      }
                  })
              )
          }
          if(accountInfo?.data){
              // console.log('accountInfo', accountInfo.data);
              // console.log('agentInfo', agentInfo.data.activeFlag);
              setUsername(accountInfo.data.humanName);
              setEmail(accountInfo.data.accountKey);
              setValue("education", agentInfo?.data?.education);
              setValue("career", agentInfo?.data?.career);
              setValue("mainArea", agentInfo?.data?.mainArea);
              setValue("subArea1", agentInfo?.data?.subArea1);
              setValue("subArea2", agentInfo?.data?.subArea2);
              setValue("subArea3", agentInfo?.data?.subArea3);
              setValue("officeName", agentInfo?.data?.officeName);
              setValue("activeFlag", agentInfo?.data?.activeFlag);
          }
      }
      
  },[agentNo, agentInfo, accountInfo]);

  useEffect(() => {
    setIsActive(agentInfo?.data?.activeFlag);
  }, [agentInfo?.data?.activeFlag]);

  const uploadPDF = () => {
    if(!document.getElementById("inputResume").files[0]){
      dispatch(
        setAlertModal({
          modalState: true,
          modalData: {
            title: "이력서 업로드 실패",
            message: "이력서 파일을 선택해주세요."
          }
        })
      )
      return;
    } else if (document.getElementById("inputResume").files[0].type !== "application/pdf"){
      dispatch(
        setAlertModal({
          modalState: true,
          modalData: {
            title: "이력서 업로드 실패",
            message: "PDF 파일만 업로드 가능합니다."
          }
        })
      )
      return;
    }
    const file = document.getElementById("inputResume").files[0];
    const formData = new FormData();
    formData.append("file", file)
    formData.append("userId", accountId)
    
    dispatch(
      setLoadingModal({
        modalState: true,
      })
    )
    uploadResumeAPI(formData)
      .unwrap()
      .then((res) => {
        dispatch(
          setTwoButtonModal({
            modalState: true,
            modalData: {
              title: "이력서 업로드 성공",
              message: res.data,
            },
            callback: (e) => {
              if(e){
                updateFields(res.data)
              }
            },
          })
        )
      })
      .catch((err) => {
        // console.log('uploadPDF err', err);
      })
      .finally(() => {
        dispatch(
          setLoadingModal({
            modalState: false,
          })
        )
      });

  }

  const updateFields = (data) => {
      // 주요 학력 필드에 값을 넣어줌
      var eduString = "";
      for(var index in data.education){
        eduString += data.education[index] + "\n"
      }
      eduString = eduString.substring(0, eduString.length - 2);
      document.getElementById("inputMainEducation").value = eduString;
      setValue("education", eduString);
      // 주요 경력 필드에 값을 넣어줌
      var careerString = "";
      for(var careerIdx in data.career){
        careerString += data.career[careerIdx] + "\n"
      }
      careerString = careerString.substring(0, careerString.length - 2);
      document.getElementById("inputMainCareer").value = careerString;
      setValue("career", careerString);
      // 전문 분야 필드에 값을 넣어줌
      if(data?.areas.length >0 ) {
        document.getElementById("inputMainArea").value = data.areas[0];
        setValue("mainArea", data.areas[0]);
      }
      // 부 분야 1 필드에 값을 넣어줌
      if(data?.areas.length >1 ) {
        document.getElementById("inputSubArea1").value = data.areas[1];
        setValue("subArea1", data.areas[1]);
      }
      // 부 분야 2 필드에 값을 넣어줌
      if(data?.areas.length >2 ) {
        document.getElementById("inputSubArea2").value = data.areas[2];
        setValue("subArea2", data.areas[2]);
      }
      // 부 분야 3 필드에 값을 넣어줌
      if(data?.areas.length >3 ) {
        document.getElementById("inputSubArea3").value = data.areas[3];
        setValue("subArea3", data.areas[3]);
      }
      // 사무실 이름 필드에 값을 넣어줌
      document.getElementById("inputOfficeName").value = data.officeName;
      setValue("officeName", data?.officeName? data.officeName : '');
  }

  const onSubmit = (data) => {
    // console.log('onSubmit data', data);
    const updateParam = {
      agentNo: agentNo,
      ...data,
    }
    // console.log('updateParam', updateParam);
    dispatch(
      setLoadingModal({
        modalState: true,
      })
    )
    updateAgentInfoAPI(updateParam)
      .unwrap()
      .then((res) => {
        // console.log('updateAgentInfoAPI res', res);
        // reload agent info
        refetch();
      }).catch((err) => {
        // console.log('updateAgentInfoAPI err', err);
      }).finally(() => {
        dispatch(
          setLoadingModal({
            modalState: false,
          })
        )
      });

  }
  // console.log('agentInfo', agentInfo);
  // console.log('activeFlag', agentInfo?.data?.activeFlag)
  return (
    <main style={{ minHeight: "800px", background: "#e5ecef" }}>
      <section>
        <Container className="container">
          <div className="row">
            {/* left navigator START */}
            <div className="col-lg-3 col-12">
              <UserInfo className="d-none d-lg-block">
                  <p className="user_name">{username}</p>
                  <p className="user_email">{email}</p>
              </UserInfo>

              <MyPageMenu className="d-none d-lg-block">
                <p className="title">회원정보 수정</p>
                <ul>
                  <li>
                    <Link to={"/mypage"}>
                    매칭 정보
                    </Link>
                  </li>
                  <li>
                    <Link to={"/mypage/modify"}>회원정보 수정</Link>
                  </li>
                  {role === "attorney" && (
                    <li>
                        <Link to={"/mypage/resumeModify"} className="active">전문 정보 수정</Link>
                    </li>
                  )}
                  <li onClick={() => Storage.logout()}>
                    <Link>로그아웃</Link>
                  </li>
                </ul>
              </MyPageMenu>
            </div>
            {/* left navigator END */}

            {/* right content START */}
            <div className="col-lg-9 col-12">
              <div className="row">
                <div className="col-12">
                  <h2>전문 정보 수정</h2>
                  <hr />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                      <div className="col-lg-6 col-12">
                        <div className="mb-3">
                          <label htmlFor="inputName" className="form-label">
                            이름
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="inputName"
                            defaultValue={username}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="mb-3">
                          <label htmlFor="activeFlag" className="form-label">
                            매칭 활성화 여부
                          </label>
                          <div style={{width:"100%"}}>
                            <label class="switch">
                              <input type="checkbox" id="activeFlag" defaultChecked={isActive} {...register("activeFlag")} />
                              <span class="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="mb-3">
                          <label htmlFor="inputAgentNo" className="form-label">
                            대리인 번호
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="inputAgentNo"
                            defaultValue={agentInfo?.data?.agentNo}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="mb-3">
                          <label htmlFor="inputQualification" className="form-label">
                            자격 사항
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="inputQualification"
                            defaultValue={agentInfo?.data?.qualification}
                            disabled
                          />
                        </div>
                      </div>

                      {/* 사무실 이름 */}
                      <div className="col-12">
                        <div className="mb-3">
                          <label htmlFor="inputOfficeName" className="form-label">
                            소속
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="inputOfficeName"
                            defaultValue={agentInfo?.data?.officeName}
                            {...register("officeName")}
                          />
                        </div>
                      </div>

                      {/* 주요 학력 */}
                      <div className="col-12">
                        <div className="mb-3">
                          <label htmlFor="inputMainEducation" className="form-label">
                            주요 학력
                          </label>
                          <textarea
                            style={{minHeight:"5rem", overflowY:"hidden", resize:"none"}}
                            type="text"
                            className="form-control"
                            id="inputMainEducation"
                            defaultValue={agentInfo?.data?.education}
                            onKeyDown={(e) => resizeArea(e.target)}
                            onKeyUp={(e) => resizeArea(e.target)}
                            {...register("education")}
                          />
                        </div>
                      </div>
                      {/* 주요 경력 */}
                      <div className="col-12">
                        <div className="mb-3">
                          <label htmlFor="inputMainCareer" className="form-label">
                            주요 경력
                          </label>
                          <textarea
                            style={{minHeight:"5rem", overflowY:"hidden", resize:"none"}}
                            type="text"
                            className="form-control"
                            id="inputMainCareer"
                            defaultValue={agentInfo?.data?.career}
                            onKeyDown={(e) => resizeArea(e.target)}
                            onKeyUp={(e) => resizeArea(e.target)}
                            {...register("career")}
                          />
                        </div>
                      </div>

                      {/* 전문 분야 */}
                      <div className="col-12">
                        <div className="mb-3">
                          <label htmlFor="inputMainArea" className="form-label">
                            전문 분야
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="inputMainArea"
                            defaultValue={agentInfo?.data?.mainArea}
                            {...register("mainArea")}
                          />
                        </div>
                      </div>
                      <div className="col-12"/>
                      {/* 부 분야 1 */}
                      <div className="col-12">
                        <div className="mb-3">
                          <label htmlFor="inputSubArea1" className="form-label">
                            부 분야 1
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="inputSubArea1"
                            defaultValue={agentInfo?.data?.subArea1}
                            {...register("subArea1")}
                          />
                        </div>
                      </div>
                      {/* 부 분야 2 */}
                      <div className="col-12">
                        <div className="mb-3">
                          <label htmlFor="inputSubArea2" className="form-label">
                            부 분야 2
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="inputSubArea2"
                            defaultValue={agentInfo?.data?.subArea2}
                            {...register("subArea2")}
                          />
                        </div>
                      </div>
                      {/* 부 분야 3 */}
                      <div className="col-12">
                        <div className="mb-3">
                          <label htmlFor="inputSubArea3" className="form-label">
                            부 분야 3
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="inputSubArea3"
                            defaultValue={agentInfo?.data?.subArea3}
                            {...register("subArea3")}
                          />
                        </div>
                      </div>
                      {/* upload PDF file */}
                      <div className="col-6">
                        <div className="mb-3">
                          <label htmlFor="inputResume" className="form-label">
                            이력서
                          </label>
                          <input
                            type="file"
                            className="form-control"
                            id="inputResume"
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="mb-3">
                          <label style={{width:"100%"}} htmlFor="inputResume" className="form-label">
                            &nbsp;
                          </label>
                          <button type="button" className="btn btn-info" onClick={uploadPDF}>
                            이력서 업로드 하기
                          </button>
                        </div>
                        </div>
                    </div>
                    {/* on submit button */}
                    <div className="row">
                      <div className="col-12">
                        <button style={{width:"100%", minHeight:"3rem", marginTop:"20px"}} type="submit" className="btn btn-primary">
                          저장하기
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            {/* right content END */}
          </div>
        </Container>
      </section>
    </main>
  );
}

const Container = styled.div`
  padding: 40px 0;

  @media (max-width: 991px) {
    padding: 40px 0;
  }
`;

const UserInfo = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fafbfe;
  padding: 20px;

  p.user_name {
    color: #070143;
    font-size: 25px;
    font-weight: 700;
    line-height: 1.2;
    padding: 3.4px 10px;
  }
  p.user_email {
    color: #687693;
    font-size: 15px;
    padding: 10px;
  }
`;

const resizeArea = (obj) => {
  obj.style.height = '1px';
  obj.style.height = (12 + obj.scrollHeight) + 'px';
}

const MyPageMenu = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: transparent;
  margin-top: 65px;

  p.title {
    color: #070143;
    font-size: 23px;
    padding-bottom: 10px;
    position: relative;
    margin-bottom: 30px;
    width: 100%;

    &::before {
      position: absolute;
      width: 55px;
      height: 4px;
      left: 0;
      bottom: 0;
      background-color: #202d90;
      border-radius: 10px;
      content: "";
    }

    &::after {
      position: absolute;
      width: calc(80% - calc(var(--bs-gutter-x) * 0.5));
      height: 4px;
      left: 65px;
      bottom: 0;
      background-color: #f2f2f2;
      border-radius: 10px;
      content: "";
    }
  }

  ul {
    li {
      font-size: 19px;
      position: relative;

      & + li {
        margin-top: 12px;
      }

      a {
        color: #687693;
        display: block;
        width: 100%;
        transition: color 0.3s ease-in-out;

        &:hover {
          color: #1b267b;
        }

        &.active {
          color: #1b267b;
        }
      }
    }
  }
`;


export default Contents;