import React, { useState } from "react";
import styled from "styled-components";
import { Storage } from "../../../modules/Storage";
import { useAppDispatch } from "../../../store";
import { setPaymentModal } from "../../../store/slice/modal";

const productListClient = [
  {
    productId: 11,
    grade: "Free",
    price: 0,
    priceOriginal: 1000,
    priceText: "무료",
    priceTextUnit: "",
    productInfoHtml: JSON.stringify([
      "3번의 무료 챗봇 대화<br/>+",
      "각 질문당 300자 이내의 답변<br/>+",
      "적합한 변리사 정보 제공<br/>",
      "&nbsp;",
      "&nbsp;"
    ]),
    etc: "",
  },
  {
    productId: 12,
    grade: "1 Day",
    price: 1980,
    priceOriginal: 3000,
    priceText: "1,980",
    priceTextUnit: "원",
    productInfoHtml: JSON.stringify([
      "10번의 무료 챗봇 대화<br/>+",
      "각 질문당 500자 이내의 답변<br/>+",
      "상담 내용 저장<br/>+",
      "상담 기반 적합한 변리사 정보 제공"
    ]),
    etc: "",
  },
  {
    productId: 13,
    grade: "7 Day",
    price: 9900,
    priceOriginal: 29900,
    priceText: "9,900",
    priceTextUnit: "원",
    productInfoHtml: JSON.stringify([
      "하루 20번의 무료 챗봇 대화<br/>+",
      "각 질문당 1000자 이내의 답변<br/>+",
      "상담 내용 저장<br/>+",
      "상담 기반 적합한 변리사 정보 제공"
    ]),
    etc: "",
  },
  {
    productId: 14,
    grade: "1 Month",
    price: 12900,
    priceOriginal: 33000,
    priceText: "12,900",
    priceTextUnit: "원/월",
    productInfoHtml: JSON.stringify([
      "하루 30번의 무료 챗봇 대화<br/>+",
      "각 질문당 1000자 이내의 답변<br/>+",
      "상담 내용 저장<br/>+",
      "상담 기반 적합한 변리사 정보 제공"
    ]),
    etc: "",
  },
];

const productListAttorney = [
  {
    productId: 21,
    grade: "개인고객",
    price: 3300,
    priceOriginal: 10000,
    priceText: "3,300",
    priceTextUnit: "원/건당",
    productInfoHtml: JSON.stringify([
    ]),
  },
  {
    productId: 22,
    grade: "기업고객",
    price: 9900,
    priceOriginal: 19900,
    priceText: "9,900",
    priceTextUnit: "원/건당",
    productInfoHtml: JSON.stringify([
    ]),
  },
  {
    productId: 23,
    grade: "해외 개업고객",
    price: 12900,
    priceOriginal: 29900,
    priceText: "12,900",
    priceTextUnit: "원/건당",
    productInfoHtml: JSON.stringify([
    ]),
  },
];

const PriceTitle = styled.h2`
  @media (max-width: 1439px) {
    font-size: 1.88rem !important;
  }

  @media (max-width: 992px) {
    font-size: 2rem !important;
  }

  @media (max-width: 650px) {
    font-size: 1.7rem !important;
  }
`;

const Pricing = () => {
  const [activeGrid, setActiveGrid] = useState(null);
  const isAttorney = Storage.get("role")?.includes("attorney");
  const dispatch = useAppDispatch();
  const openPaymentModal = (product) => {
    console.log("openPaymentModal", product);
    dispatch(setPaymentModal({
      modalState: true,
      modalData: {
        productList: isAttorney ? productListAttorney : productListClient,
      },
    }));
  };

  return (
    <section className="wpo-pricing-section section-padding">
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-lg-5">
            <div className="wpo-section-title">
              <PriceTitle>인디프 서비스 비용 안내</PriceTitle>
            </div>
          </div>
        </div>
        <div className="pricing-grids clearfix">
          <p className="pricing-etc">(VAT별도)</p>
          {isAttorney ? 
            <>
              {productListAttorney.map((product, index) => (
                <div
                  key={index}
                  className={`grid ${activeGrid === product.productId ? 'active' : ''}`}
                  onMouseEnter={() => setActiveGrid(product.productId)}
                  onMouseLeave={() => setActiveGrid(null)}
                  onClick={() => {
                    console.log("product clicked")
                    openPaymentModal(product);
                  }}
                >
                  <div className="type">
                    <h5 style={{ fontSize: "24px" }}>{product.grade}</h5>
                  </div>
                  <div className="pricing-header">
                    <div>
                      <h4 className="price">
                        {product.priceText}
                        <span>{product.priceTextUnit}</span>
                      </h4>
                      <p>{product.priceOriginal.toLocaleString()}</p>
                    </div>
                  </div>
                  <div className="pricing-body">
                    <ul>
                      {JSON.parse(product.productInfoHtml).map((item, index) => (
                        <li key={index} dangerouslySetInnerHTML={{ __html: item }}></li>
                      ))}
                    </ul>
                    {product.etc && <p dangerouslySetInnerHTML={{ __html: product.etc }}></p>}
                  </div>
                </div>
              ))}
            </>
          :
            <>
              {productListClient.map((product, index) => (
                <div
                  key={index}
                  className={`grid ${activeGrid === product.productId ? 'active' : ''}`}
                  onMouseEnter={() => setActiveGrid(product.productId)}
                  onMouseLeave={() => setActiveGrid(null)}
                  onClick={() => {
                    console.log("product clicked")
                    openPaymentModal(product);
                  }}
                >
                  <div className="type">
                    <h5 style={{ fontSize: "24px" }}>{product.grade}</h5>
                  </div>
                  <div className="pricing-header">
                    <div>
                      <h3 className="price">
                        {product.priceText}
                        <span>{product.priceTextUnit}</span>
                      </h3>
                      <p>{product.priceOriginal.toLocaleString()}</p>
                    </div>
                  </div>
                  <div className="pricing-body">
                    <ul>
                      {JSON.parse(product.productInfoHtml).map((item, index) => (
                        <li key={index} dangerouslySetInnerHTML={{ __html: item }}></li>
                      ))}
                    </ul>
                    {product.etc && <p dangerouslySetInnerHTML={{ __html: product.etc }}></p>}
                  </div>
                </div>
              ))}
            </>
          }
        </div>
      </div>
    </section>
  );

}

export default Pricing;