import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { getNoticeDetail } from "../../../api/axiosApi";
import styled from "styled-components";

const Details = () => {
    const [noticeDetail, setNoticeDetail] = useState({});//[noticeDetail, setNoticeDetail
    const { id } = useParams();

    useEffect(() => {
        getNoticeDetail(id).then((response) => {
            // console.log(response);
            setNoticeDetail(response.data.data);
        }).catch((error) => {
            console.log(error);
        });
    }
    , [id]);

    return (
        <main style={{ minHeight: "800px", background: "#e5ecef" }}>
            <section>
                <Container className="container">
                    <div className="row">
                        <div className="col-lg-3 col-12">
                            <NoticePageMenu className="d-none d-lg-block">
                                <p className="title">공지사항</p>
                                <ul>
                                    <li>
                                        <Link to="/notice/notice" className={noticeDetail.noticeKind == "NOTICE" ? "active" : ""}>공지사항</Link>
                                    </li>
                                    <li>
                                        <Link to="/notice/faq" className={noticeDetail.noticeKind == "FAQ" ? "active" : ""}>FAQ</Link>
                                    </li>
                                </ul>
                            </NoticePageMenu>
                        </div>

                        <div className="col-lg-9 col-12">
                            <div className="row">
                                <div className="col-12">
                                    <div className="card mb-3">
                                        <div className="row g-0">
                                            <div className="col-md-1">
                                                <div className="card-body d-flex justify-content-center align-items-center">
                                                    {noticeDetail.noticeId}
                                                </div>
                                            </div>
                                            <div className="col-md-8">
                                                <div className="card-body">
                                                    <h5 className="card-title">{noticeDetail.title}</h5>
                                                    <p className="card-text">{noticeDetail.writer}</p>
                                                    <p className="card-text">
                                                        <small className="text-muted">{noticeDetail.createdAt}</small>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-body">
                                            {/* noticeDetail.contents html to dom */}
                                            <div dangerouslySetInnerHTML={{ __html: noticeDetail.contents }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </Container>
            </section>
        </main>
    );
}

const Container = styled.div`
  padding: 40px 0;

  @media (max-width: 991px) {
    padding: 40px 0;
  }
`;

const NoticePageMenu = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: transparent;
  margin-top: 65px;

  p.title {
    color: #070143;
    font-size: 23px;
    padding-bottom: 10px;
    position: relative;
    margin-bottom: 30px;
    width: 100%;

    &::before {
      position: absolute;
      width: 55px;
      height: 4px;
      left: 0;
      bottom: 0;
      background-color: #202d90;
      border-radius: 10px;
      content: "";
    }

    &::after {
      position: absolute;
      width: calc(80% - calc(var(--bs-gutter-x) * 0.5));
      height: 4px;
      left: 65px;
      bottom: 0;
      background-color: #f2f2f2;
      border-radius: 10px;
      content: "";
    }
  }

  ul {
    li {
      font-size: 19px;
      position: relative;

      & + li {
        margin-top: 12px;
      }

      a {
        color: #687693;
        display: block;
        width: 100%;
        transition: color 0.3s ease-in-out;

        &:hover {
          color: #1b267b;
        }

        &.active {
          color: #1b267b;
        }
      }
    }
  }
`;

export default Details;