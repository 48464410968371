import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import styled from "styled-components";
import { Storage } from "../../modules/Storage";
import { modalSelector, useAppDispatch, useAppSelector } from "../../store";
import { setAlertModal, setPaymentModal } from "../../store/slice/modal";

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .header-title {
    width: calc(100% - 32px);
    font-size: 30px;
    font-weight: 600;
    color: #333;
    text-align: center;
  }

  .header-close {
    font-size: 20px;
    cursor: pointer;

    button {
      background-color: transparent;
      border: none;
      outline: none;

      img {
        width: 20px;
        height: 20px;
      }
    }
  }
`;

const ModalContents = styled.div`
  .contents-title {
    font-size: 20px;
    font-weight: 500;
    color: #333;
    margin-top: 25px;
    margin-bottom: 10px;
  }

  .contents-box {
    padding: 20px;
    background-color: #cdcdcd;

    p.contents-subtitle {
      font-size: 16px;
      font-weight: 500;
      color: #333;
      margin-bottom: 5px;
    }

    p.contents-info {
      font-size: 15px;
      font-weight: 400;
      margin-left: 17px;
      margin-bottom: 10px;
      color: #1b267b;
    }

    ul {
      line-height: 1.8;
      padding-left: 20px;
      li {
        list-style-type: square;
      }
    }
  }
`;

const PriceTitle = styled.h2`
    @media (max-width: 1439px) {
    font-size: 1.88rem !important;
    }

    @media (max-width: 992px) {
    font-size: 2rem !important;
    }

    @media (max-width: 650px) {
    font-size: 1.7rem !important;
    }
    `;

    const PurchaseButton = styled.button`
    width: 100%;
    @media (max-width: 1199px) {
    width: calc(100% - 50px);
    }
`;

const IMP = window.IMP;
IMP.init("imp36555036");

const PaymentModal = () => {
    const { payment: modal } = useAppSelector(modalSelector);
    const dispatch = useAppDispatch();
    const[activeGrid, setActiveGrid] = useState(null);
    const [buyer_email] = useState(Storage.get("accountKey"));
    const [isLogin] = useState(Storage.get("accountKey") ? true : false);
    useEffect(() => {
        if (modal.modalState) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    }, [modal.modalState]);
    
    const closeModal = () => {
        dispatch(setPaymentModal());
    };

    const onClickPurchase = async (modalData) => {
        if (!isLogin) {
            dispatch(setPaymentModal());
            dispatch(setAlertModal({
                modalState: true,
                modalType: "login",
                modalData: {
                    title: "로그인이 필요합니다.",
                    message: "로그인 후 이용해주세요.",
                },
            }));
            return;
        }

        if (activeGrid === null) {
            dispatch(setAlertModal({
                modalState: true,
                modalType: "alert",
                modalData: {
                    title: "플랜을 선택해주세요.",
                    message: "플랜을 선택해주세요.",
                },
            }));
            return;
        }

        const params = {
            pg:"nice_v2.IM0016038m", // real payment
            pay_method: "card",
            merchant_uid: `indif_${new Date().getTime()}`,
            name: modalData.productList[activeGrid].grade + " 플랜",
            amount: modalData.productList[activeGrid].price * 1.1,
            buyer_email: buyer_email,
        };

        IMP.request_pay(params, async (rsp) => {
            //callback
            if (rsp.error_code != null){
                let msg = "결제에 실패하였습니다.";
                if (rsp.error_code === "F400") {
                    msg = "결제가 취소되었습니다.";
                } else if (rsp.error_code === "F500") {
                    msg = "결제에 실패하였습니다.";
                }
                dispatch(setAlertModal({
                    modalState: true,
                    modalType: "alert",
                    modalData: {
                        title: "결제 실패",
                        message: msg,
                    },
                }));
                return;
            }

            // 결제 성공
            dispatch(setAlertModal({
                modalState: true,
                modalType: "alert",
                modalData: {
                    title: "결제 완료",
                    message: "결제가 완료되었습니다.",
                },
            }));
        });

    }

    return (
        <Modal
            className="payment-modal"
            isOpen={modal.modalState}
            onRequestClose={closeModal}
            contentLabel="인디프 플랜 선택"
            style={{
                overlay: {
                position: "fixed",
                inset: 0,
                backgroundColor: "rgba(0, 0, 0, 0.75)",
                zIndex: 9998, // 원하는 z-index 값
                overflow: "auto",
                },
                content: {
                position: "absolute",
                minWidth: "390px",
                top: "40px",
                bottom:"40px",
                left: "50%",
                transform: "translateX(-50%)",
                border: "1px solid rgb(204, 204, 204)",
                background: "rgb(255, 255, 255)",
                overflow: "auto",
                borderRadius: "4px",
                outline: "none",
                padding: "20px",
                },
            }}
        >
            <section className="wpo-pricing-section">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-md-5 col-sm-7 mt-2">
                            <div className="wpo-section-title">
                                <PriceTitle>인디프 플랜 선택</PriceTitle>
                            </div>
                            <div className="pricing-modal-close">
                                <button onClick={closeModal}>
                                    <i className="ti-close">X</i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="pricing-grids clearfix">
                        <p className="pricing-etc">(VAT별도)</p>
                        {modal?.modalData?.productList?.map((product, index) => {
                            if (product.grade !== "Free") {
                                return (
                                    <div
                                        key={index}
                                        className={`grid popup ${activeGrid === index ? "active" : ""}`}
                                        onClick={() => setActiveGrid(activeGrid === index ? null : index)}
                                    >
                                        <div className="type">
                                            <h5 style={{ fontSize: "24px" }}>{product.grade}</h5>
                                        </div>
                                        <div className="pricing-header">
                                            <div>
                                                <h4 className="price">
                                                    {product.priceText}
                                                    <span>{product.priceTextUnit}</span>
                                                </h4>
                                                <p>{product.priceOriginal.toLocaleString()}</p>
                                            </div>
                                        </div>
                                        <div className="pricing-body">
                                            <ul>
                                                {JSON.parse(product.productInfoHtml).map((item, index) => (
                                                    <li key={index} dangerouslySetInnerHTML={{ __html: item }}></li>
                                                ))}
                                            </ul>
                                            {product.etc && <p dangerouslySetInnerHTML={{ __html: product.etc }}></p>}
                                        </div>
                                    </div>
                                );
                            }
                        })}
                    </div>

                    <div className="row align-items-center justify-content-center">
                        <div className="grid col-lg-4 col-md-8 col-sm-12 mt-5 mb-5">
                        <PurchaseButton className="get-started popup" onClick={() => onClickPurchase(modal.modalData)}>
                            결제하기
                        </PurchaseButton>
                        </div>
                    </div>

                </div>
            </section>

        </Modal>
    );
};

export default PaymentModal;