import { Pagination } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { getNoticeListWithPage } from "../../../api/axiosApi";

const Contents = ({noticeKind}) => {
    const [noticeList, setNoticeList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const navigate = useNavigate();

    const onPageChangeHandler = (event, value) => {
        getNoticeListWithPage(noticeKind, value).then((response)=>{
            // console.log(response);
            setNoticeList(response.data.data);
            setCurrentPage(value);
        }    
        ).catch((error)=>{
            console.log(error);
        });
    };
    
    useEffect(()=>{
        // console.log(noticeKind);
        getNoticeListWithPage(noticeKind, 1).then((response)=>{
            // console.log(response);
            setNoticeList(response.data.data);
            setCurrentPage(1);
        }    
        ).catch((error)=>{
            console.log(error);
        });
    },[noticeKind]);
    return (
        <main style={{ minHeight: "800px", background: "#e5ecef" }}>
            <section>
                <Container className="container">
                    <div className="row">
                        <div className="col-lg-3 col-12">
                            <NoticePageMenu className="d-none d-lg-block">
                                <p className="title">공지사항</p>
                                <ul>
                                    <li>
                                        <Link to="/notice/notice" className={noticeKind == "notice" ? "active" : ""}>공지사항</Link>
                                    </li>
                                    <li>
                                        <Link to="/notice/faq" className={noticeKind == "faq" ? "active" : ""}>FAQ</Link>
                                    </li>
                                </ul>
                            </NoticePageMenu>
                        </div>
                        <div className="col-lg-9 col-12">
                            <div className="row" style={{minHeight:"450px"}}>
                                <ul>
                                {noticeList.map((notice, index) => (
                                    <div key={index} className="col-12" onClick={()=>{ navigate("/notice/detail/" + notice.noticeId) }}>
                                        <div className="card mb-3">
                                            <div className="row g-0">
                                                <div className="col-md-1">
                                                    <div className="card-body d-flex justify-content-center align-items-center">
                                                        {notice.noticeId}
                                                    </div>
                                                </div>
                                                <div className="col-md-8">
                                                    <div className="card-body">
                                                        <h5 className="card-title">{notice.title}</h5>
                                                        <p className="card-text">{notice.writer.length > 20 ? notice.writer.substring(0, 20) + "..." : notice.writer}</p>
                                                        <p className="card-text">
                                                            <small className="text-muted">{notice.createdAt}</small>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                </ul>
                            </div>
                            <div className="row" style={{width:"100%", marginTop:"15px"}}>
                                <Pagination page={currentPage || 1} color="primary" count={Math.ceil(noticeList.length / 10)} onChange={onPageChangeHandler} />
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
        </main>
    );

}

const Container = styled.div`
  padding: 40px 0;

  @media (max-width: 991px) {
    padding: 40px 0;
  }
`;

const NoticePageMenu = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: transparent;
  margin-top: 65px;

  p.title {
    color: #070143;
    font-size: 23px;
    padding-bottom: 10px;
    position: relative;
    margin-bottom: 30px;
    width: 100%;

    &::before {
      position: absolute;
      width: 55px;
      height: 4px;
      left: 0;
      bottom: 0;
      background-color: #202d90;
      border-radius: 10px;
      content: "";
    }

    &::after {
      position: absolute;
      width: calc(80% - calc(var(--bs-gutter-x) * 0.5));
      height: 4px;
      left: 65px;
      bottom: 0;
      background-color: #f2f2f2;
      border-radius: 10px;
      content: "";
    }
  }

  ul {
    li {
      font-size: 19px;
      position: relative;

      & + li {
        margin-top: 12px;
      }

      a {
        color: #687693;
        display: block;
        width: 100%;
        transition: color 0.3s ease-in-out;

        &:hover {
          color: #1b267b;
        }

        &.active {
          color: #1b267b;
        }
      }
    }
  }
`;

export default Contents;