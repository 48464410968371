import '../css/chatbot.css';
const chatbotToggler = document.querySelector(".chatbot-toggler");
const closeBtn = document.querySelector(".close-btn");
const chatbox = document.querySelector(".chatbox");
const chatInput = document.querySelector(".chat-input textarea");
const sendChatBtn = document.querySelector(".chat-input span");
const isDev = false;
const baseURL = isDev ? "http://localhost:8084/api" : "https://indieip.startlump.com/api";

let thread_id = null;

let userMessage = null;
const inputInitHeight = chatInput.scrollHeight;

// var converter = new showdown.Converter();

const getAuthToken = () => {
    return localStorage.getItem("authToken");
}

let authToken = getAuthToken();

const getAccountKey = () => {
    return localStorage.getItem("accountKey");
}

let accountKey = getAccountKey();

const createChatLi = (message, className) => {
    const chatLi = document.createElement("li");
    chatLi.classList.add("chat", `${className}`);
    let chatContent = className === "outgoing" ? `<p></p>` : `<span class="material-symbols-outlined">smart_toy</span><p></p>`;
    chatLi.innerHTML = chatContent;
    chatLi.querySelector("p").textContent = message;
    return chatLi;
}

const createChatLiWithHTML = (message, className) => {
    const chatLi = document.createElement("li");
    chatLi.classList.add("chat", `${className}`);
    let chatContent = className === "outgoing" ? `<p></p>` : `<span class="material-symbols-outlined">smart_toy</span><p></p>`;
    chatLi.innerHTML = chatContent;
    chatLi.querySelector("p").innerHTML = message;
    return chatLi;
}

const removeChatLi = (chatElement) => {
    chatElement.remove();
}

const generateResponse = (chatElement) => {
    const messageElement = chatElement.querySelector("p");
    if(authToken == null) {
        messageElement.textContent = "제노스 서비스 이용을 위해 로그인을 해주세요";
        chatbox.scrollTo(0, chatbox.scrollHeight)
        return;
    }
    sendToChatbot(userMessage, messageElement);
}

const sendToChatbot = (message, messageElement) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("X-AUTH-TOKEN", authToken)

    const raw = JSON.stringify({
        "accountKey": accountKey,
        "question": message,
        "thread_id": thread_id
    });

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
    };

    fetch(baseURL + "/chat/sendChatBot", requestOptions)
    .then((response) => response.text())
    .then((result) => {
        const resultJson = JSON.parse(result)
        messageElement.textContent = resultJson.data;
        chatbox.scrollTo(0, chatbox.scrollHeight)
        // console.log("챗봇 결과",resultJson);
        // let message = "";
        // if(resultJson.data != null) {
        //     message = converter.makeHtml(resultJson.data);
        // }
        // console.log(message);
        // removeChatLi(messageElement.parentElement);
        // chatbox.appendChild(createChatLiWithHTML(message, "incoming"));
    })
    .catch((error) => console.error(error))
    .finally(() => {
        sendChatBtn.disabled = false;
        chatInput.disabled = false;
    })
}

const requestThreadId = () => {
    //get request to /api/chat/getThreadId
    const headers = new Headers();
    headers.append("X-AUTH-TOKEN", authToken);
    const requestOptions = {
        method: "GET",
        headers: headers,
        redirect: "follow"
      };
    fetch(baseURL + "/chat/getThreadId", requestOptions)
    .then((response) => response.text())
    .then((result) => {
        thread_id = result;
        if(thread_id){
            sendChatBtn.disabled = false;
            chatInput.disabled = false;
        }
    })
    .catch((error) => console.error(error))
};

const handleChat = () => {
    userMessage = chatInput.value.trim();
    if(!userMessage) return;

    // Clear the input textarea and set its height to default
    chatInput.value = "";
    chatInput.style.height = `${inputInitHeight}px`;

    // Append the user's message to the chatbox
    chatbox.appendChild(createChatLi(userMessage, "outgoing"));
    chatbox.scrollTo(0, chatbox.scrollHeight);

    // Display "Thinking..." message while waiting for the response
    const incomingChatLi = createChatLi("Thinking...", "incoming");
    chatbox.appendChild(incomingChatLi);
    chatbox.scrollTo(0, chatbox.scrollHeight);
    sendChatBtn.disabled = true;
    chatInput.disabled = true;
    generateResponse(incomingChatLi);
}

chatInput.addEventListener("input", () => {
    // Adjust the height of the input textarea based on its content
    chatInput.style.height = `${inputInitHeight}px`;
    chatInput.style.height = `${chatInput.scrollHeight}px`;
});

chatInput.addEventListener("keydown", (e) => {
    // If Enter key is pressed without Shift key and the window
    // width is greater than 800px, handle the chat
    if (e.isComposing || e.keyCode === 229) return; 
    if(e.key === "Enter" && !e.shiftKey && window.innerWidth > 800) {
        e.preventDefault();
        handleChat();
    }
});

if(thread_id == null ) {
    sendChatBtn.disabled = true;
    chatInput.disabled = true;
    requestThreadId();
}

sendChatBtn.addEventListener("click", handleChat);
closeBtn.addEventListener("click", () => document.body.classList.remove("show-chatbot"));
chatbotToggler.addEventListener("click", () => document.body.classList.toggle("show-chatbot"));
