import React from "react";
import Contents from "../components/pages/login/Contents";
import Header from "../components/header/Header";

const Login = () => {
  return (
    <>
      <Header />
      <Contents />
    </>
  );
};

export default Login;
