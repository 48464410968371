import React from "react";
import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import Contents from "../components/pages/notice/Contents";
import { useParams } from "react-router-dom";

const Notice = () => {
    return (
        <>
            <Header />
            <Contents noticeKind={useParams().kind}/>
            <Footer />
        </>
    );
}

export default Notice;