import React from "react";
import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import Contents from "../components/pages/pricing/Contents";

const Pricing = () => {
    return (
        <div>
        <Header />
        <Contents />
        <Footer />
        </div>
    );
}
export default Pricing;